<template>
  <div>
    <window-header></window-header>
    <nav style="justify-content: space-between;">
      <button
        class="button button-fill"
        :class="{ disabled: tabDisabled(activeTab - 1) }"
        @click="$refs['main-tabs'].prevTab()"
      >
        <fa-icon :icon="['fas', 'chevron-left']" />
        <span>{{ $t("back") }}</span>
      </button>
      <button
        class="button button-fill"
        :class="{ disabled: tabDisabled(activeTab + 1) }"
        @click="$refs['main-tabs'].nextTab()"
        v-if="activeTab < 2"
      >
        <span>{{ $t("next") }}</span>
        <fa-icon :icon="['fas', 'chevron-right']" />
      </button>
      <button
        v-if="activeTab == 2"
        :class="{ disabled: !lineasSelected().length }"
        class="button button-fill"
        @click="finalizar"
      >
        <span>Finalizar</span>
      </button>
    </nav>
    <section>
      <article style="height:367px">
        <field
          name="delegacion_id"
          model="factura_cliente"
          widget="m2o"
          searchable
          label="Delegación"
          placeholder=""
          width="130px"
          :readonly="mode == 'edit'"
          style="top:9px; left:5px;"
        />
        <field
          name="ndoc"
          widget="char"
          searchable
          label="Nº Liquid."
          placeholder=""
          width="67px"
          :readonly="mode == 'edit'"
          style="top:9px; left:140px;"
        />
        <field
          name="fecha"
          widget="date"
          type="date"
          label="F. Recepción"
          help="Fecha"
          required
          default="today"
          width="100px"
          style="top:9px; left:210px;"
          searchable
          fontweight="bold"
        />
        <field
          name="representante_id"
          model="factura_cliente"
          widget="m2o"
          label="Representante"
          placeholder="Rep."
          required
          width="220px"
          style="top:8px; left:312px;"
          searchable
          :filter="[['activo','=',1]]"
          :fields="[
            'comision_fija_liquidacion',
            'irpf',
            { name: 'impuesto_id', fields: ['valor'] },
            'nacionalidad',
            'penalizacion_forma_pago',
            { name: 'forma_pago_id', fields: ['nombre', 'penalizacion'] },
            {
              name: 'tipos_comision',
              fields: [
                { name: 'comision_id', fields: ['codigo'] },
                'peso_incmerma',
                't',
                'peso_incmerma',
                'peso_directas',
                'peso_indirectas',
                'unidad_directas',
                'unidad_indirectas',
                'beneficio_directas',
                'beneficio_indirectas',
                'venta_directas_metal',
                'venta_directas_metal_ni',
                'venta_indirectas_metal',
                'venta_indirectas_metal_ni'
              ]
            }
          ]"
          @change="onChangeRepresentante"
        />
        <field
          name="divisa_id"
          model="factura_cliente"
          widget="m2o"
          searchable
          label="Liquidar en..."
          placeholder="Cód."
          width="90px"
          style="top:9px; left:565px;"
          :readonly="mode == 'edit'"
        />

        <r-tabs
          ref="main-tabs"
          :wt="formData.wt"
          style="top:55px; left:0px; height:310px;"
          :buttons="['1. Seleccion', '2. Líneas', '3. Pie']"
          :classButton="classButtonTabDisabled"
          :styleButton="'width:33%;'"
          @active="onActiveTab"
        >
          <div class="tab">
            <fieldset style="position: relative; left: 10px; top: 10px; width: 633px; height:180px">
              <legend>Datos de selección:</legend>
              <span style="position: absolute; top: 40px; right:295px;width:95px;text-align: center;">DESDE</span>
              <span style="position: absolute; top: 40px; right:200px;width:95px;text-align: center;">HASTA</span>
              <field
                name="delegacion_desde"
                widget="char"
                label="Delegacion"
                inline-label
                width="90px"
                style="top:70px; right:295px;"
              />
              <field
                name="delegacion_hasta"
                widget="char"
                width="90px"
                style="top:70px; right:200px;"
              />
              <field
                name="factura_desde"
                widget="char"
                label="Factura"
                inline-label
                width="90px"
                style="top:100px; right:295px;"
              />
              <field
                name="factura_hasta"
                widget="char"
                width="90px"
                style="top:100px; right:200px;"
              />
              <field
                name="ffactura_desde"
                widget="date"
                type="date"
                label="F. Factura"
                inline-label
                width="90px"
                style="top:130px; right:295px;"
              />
              <field
                name="ffactura_hasta"
                widget="date"
                type="date"
                width="90px"
                style="top:130px; right:200px;"
              />
            </fieldset>
            <field
              v-show="showWt"
              name="incluir"
              widget="radio"
              default="facturas"
              :options="{
                'Incluir todas las facturas y notas': 'facturas_notas',
                'Incluir Sólo facturas': 'facturas',
                'Incluir Sólo Notas': 'notas'
              }"
              style="top:210px; left:80px;"
            />
          </div>
          <div class="tab">
            <field
              ref="facturas"
              structureRef="liquidacion_comisiones.facturas"
              name="facturas"
              widget="handsontable"
              :height="150"
              :width="650"
              :minRows="1"
              style="top:5px; left:4px; width: 648px;"
              customprop="facturas_customprop"
              :htSettings="htSettingsFacturas"
              :columns="[
                {
                  name: 'sel',
                  header: 'Sel.',
                  widget: 'checkbox',
                  help: 'Seleccionado'
                },
                {
                  name: 'factura_cliente_id.delegacion_id',
                  header: 'Delegación',
                  readOnly: true,
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'nombre',
                  showCode: true
                },
                //{ name: 'serie', header: 'T', readOnly: true },
                {
                  name: 'factura_cliente_id.ndoc',
                  header: 'NºDocumento',
                  readOnly: true
                },
                {
                  name: 'factura_cliente_id.cliente_id',
                  header: 'Cliente',
                  readOnly: true,
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'nombre_comercial',
                  showCode: true
                },
                {
                  name: 'factura_cliente_id.fecha',
                  type: 'date2',
                  header: 'Fecha',
                  readOnly: true
                },
                {
                  name: 'factura_cliente_id.total',
                  header: 'Total',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' }
                },
                {
                  name: 'factura_cliente_id.divisa_id',
                  header: 'Divisa',
                  readOnly: true,
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'nombre',
                  showCode: true
                },
                {
                  name: 'factura_cliente_id.cambio',
                  header: 'Cambio',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' }
                },
                {
                  name: 'factura_cliente_id.formapago_id',
                  header: 'F.pago',
                  readOnly: true,
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'descripcion'
                },
                {
                  name: 'calculo',
                  header: 'Cálculo',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' }
                },
                //HIDDEN:{name:'penalizacion',header:'Penalización',readOnly:true,type:'numeric',numericFormat: { pattern: '0,0.00'}},
                {
                  name: 'comision',
                  header: 'Comisión',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' }
                },
                {
                  name: 'tipo_cobro',
                  header: 'T.Cob.',
                  type: 'select',
                  options: ['I', 'T', 'P'],
                  help: 'I: indistinto, P: porcentaje, T: total'
                },
                //HIDDEN:{name:'cobrado',header:'T.Cob.',readOnly:true},
                {
                  name: 'porcentaje_cobrado',
                  header: '%Cob.',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  help: '% cobrado de la factura al cliente'
                },
                {
                  name: 'liquidable',
                  header: 'Liquidable',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' }
                },
                {
                  name: 'liquidado',
                  header: 'Pagado',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' }
                },
                {
                  name: 'pendiente',
                  header: 'Pendiente',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' }
                },
                {
                  name: 'liquidar',
                  header: 'Liquidar',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' }
                },
                {
                  name: 'l',
                  header: 'Liquidado',
                  type: 'checkbox',
                  help: 'Marcar la factura como liquidada'
                }
                //HIDDEN: lineas
              ]"
            />

            <field
              ref="lineas"
              structureRef="liquidacion_comisiones.lineas_factura"
              v-show="facturaSeleccionada != null"
              :name="
                'facturas.' +
                (facturaSeleccionada || 0) +
                '.factura_cliente_id.lineas'
              "
              widget="handsontable"
              :height="100"
              :width="650"
              :minRows="1"
              style="top:160px; left:4px; width: 648px;"
              customprop="lineas_customprop"
              :htSettings="htSettingsLineas"
              :columns="[
                /*{
                  name: 'sel',
                  header: 'Sel.',
                  widget: 'checkbox',
                  help: 'Seleccionado'
                },*/
                {
                  name: 'ean13',
                  header: '|||||||||',
                  help: 'Código de barras del artículo',
                  readOnly: true
                },
                {
                  name: 'articulo_id',
                  header: 'Artículo',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'codigo',
                  noOpen: true,
                  fields: [
                    { name: 'thumbnail', fields: ['imagen'] },
                    'tarifa1',
                    'tarifa2',
                    'tarifa3',
                    'tarifa4',
                    'tarifa5',
                    'tarifa6',
                    'tarifa7',
                    'tarifa8',
                    'tarifa9',
                    'tarifa10'
                  ],
                  readOnly: true
                },
                {
                  name: 'descripcion',
                  header: 'Descripción',
                  readOnly: true
                },
                {
                  name: 'c',
                  header: 'C',
                  type: 'select',
                  options: ['D', 'I']
                },
                {
                  name: 'com',
                  header: 'Com.',
                  model: 'comision',
                  type: 'm2o',
                  label: 'codigo',
                  primary: 'codigo',
                  noOpen: true
                },
                {
                  name: 'unidades',
                  header: 'Unid.',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  readOnly: true
                },
                {
                  name: 'peso',
                  header: 'Peso',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.000' },
                  readOnly: true
                },
                {
                  name: 'precio',
                  header: 'Precio',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  readOnly: true
                },
                {
                  name: 'descuentos',
                  header: 'Dtos.',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  sufix: '%',
                  readOnly: true
                },
                {
                  name: 'totallinea',
                  header: 'Total Línea',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  readOnly: true
                },
                {
                  name: 'calculo',
                  header: 'Cálculo',
                  type: 'numeric',
                  readOnly: true,
                  numericFormat: { pattern: '0,0.00' }
                },
                {
                  name: 'comision',
                  header: 'Comisión',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' }
                }
                //HIDDEN:comision_manual
              ]"
            />
          </div>
          <div class="tab">
            <field
              name="sunumerofactura"
              widget="char"
              label="Su Nº Factura"
              width="110px"
              style="top:10px; left:20px;"
            />
            <field
              name="fechasufactura"
              widget="date"
              type="date"
              label="Fecha de su Factura"
              width="110px"
              style="top:10px; left:150px;"
            />
            <fieldset style="position: relative; left: 10px; top: 50px; width: 250px; height:61px">
              <legend>Tipo Nacionalidad</legend>
              <field
                name="nacionalidad"
                widget="radio"
                default="union_europea"
                :options="{
                  Nacional: 'nacional',
                  'Extracom.': 'extracomunitario',
                  UE: 'union_europea'
                }"
                inline-label
                style="top:9px; left:15px;"
              />
            </fieldset>
            <field
              name="observaciones"
              widget="text"
              label="Observaciones"
              width="250px"
              height="100px"
              style="top:135px; left:10px;"
            />
            <fieldset style="position: relative; left: 270px; top: -42px; width: 345px; height:240px">
              <field
                name="importe_fijo"
                widget="float"
                type="number"
                dec="2"
                label="Importe fijo de comisión"
                inline-label
                width="67px"
                style="top:10px; right:15px;"
                inputStyle="text-align:right;"
              />
              <field
                name="importe_calculado"
                widget="float"
                type="number"
                dec="2"
                label="Importe calculado de comisión"
                inline-label
                width="67px"
                style="top:38px; right:15px;"
                inputStyle="text-align:right;"
                readonly
              />
              <hr style="position:absolute;top:60px;right:15px;width:250px" />
              <field
                name="base_imponible"
                widget="float"
                type="number"
                dec="2"
                label="Base imponible"
                inline-label
                width="67px"
                style="top:78px; right:15px;"
                inputStyle="text-align:right;"
                readonly
              />
              <field
                name="ivap"
                widget="float"
                type="number"
                dec="2"
                label="IVA"
                inline-label
                sufix="%"
                width="67px"
                style="top:106px; right:87px;"
                inputStyle="text-align:right;"
                readonly
              />
              <field
                name="iva"
                widget="float"
                type="number"
                dec="2"
                width="67px"
                style="top:106px; right:15px;"
                inputStyle="text-align:right;"
                readonly
              />
              <field
                name="irpfp"
                widget="float"
                type="number"
                dec="2"
                label="IRPF"
                inline-label
                sufix="%"
                width="67px"
                style="top:134px; right:87px;"
                inputStyle="text-align:right;"
                readonly
              />
              <field
                name="irpf"
                widget="float"
                type="number"
                dec="2"
                width="67px"
                style="top:134px; right:15px;"
                inputStyle="text-align:right;"
                readonly
              />
              <hr style="position:absolute;top:160px;right:15px;width:250px" />
              <field
                name="total"
                widget="float"
                type="number"
                dec="2"
                width="150px"
                style="bottom:38px; right:15px;"
                readonly
                label="Total a pagar"
                inline-label
                inputStyle="text-align:right; font-weight:normal; font-size: 1.4em!important;"
              />
              <field
                name="formapago"
                widget="select"
                :options="{
                  Contado: 'contado',
                  Transferencia: 'transferencia',
                  Pagaré: 'pagare'
                }"
                inline-label
                label="Forma de pago"
                width="150px"
                style="right:15px; bottom:10px;"
              />
            </fieldset>
          </div>
        </r-tabs>
      </article>
    </section>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import Handsontable from "handsontable";
var floatOrZero = function (val) {
  return isFinite(parseFloat(val || 0.0)) ? parseFloat(val || 0.0) : 0.0;
};
export default {
  mixins: [WindowMixin, rFormMixin],
  data: function () {
    var self = this;
    return {
      title: "Liquidación de comisiones",
      dbAdapter: "liquidacion_comision",
      primary: "ndoc",
      mode: "new",
      showWt: false,
      original_documentos_pendientes: [],
      original_lineas_pendientes: [],
      activeTab: 0,
      formData: {
        incluir: "facturas",
        delegacion_id: self.app.config.delegacion_defecto || null,
        divisa_id: self.app.config.moneda_defecto || null
      },
      facturaSeleccionada: null,
      comisiones: [],
      htSettingsFacturas: {
        afterSelection: function (r, c, r2, c2) {
          self.facturaSeleccionada = r;
        },
        beforeChange: function (changes, source) {
          if (!changes) return;
          var hf = this;
          window.$.each(changes, function (index, change) {
            var row = change[0];
            var prop = change[1];
            var oldVal = change[2];
            var newVal = change[3];
            if (prop == "tipo_cobro") {
              if (newVal == "i") changes[index][3] = "I";
              else if (newVal == "p") changes[index][3] = "P";
              else if (newVal == "t") changes[index][3] = "T";
              else if (window.$.inArray(newVal, ["I", "P", "T"]) == -1)
                changes[index][3] = "I";
            }
            if (prop == "liquidar") {
              var liquidar = parseFloat(newVal) || 0.0;
              var pendiente =
                parseFloat(hf.getSourceData()[row]["pendiente"]) || 0.0;
              if (liquidar > pendiente) {
                changes[index][3] = pendiente;
              }
              if (liquidar < 0) {
                changes[index][3] = 0.0;
              }
            }
          });
        },
        afterChange: function (changes, source) {
          if (!changes) return;
          //console.log(changes);
          var hf = this;
          window.$.each(changes, function (index, change) {
            if (!change) return;
            var row = change[0];
            var prop = change[1];
            var oldVal = change[2];
            var newVal = change[3];

            var data = hf.getSourceData()[row] || {};
            var sel = parseInt(data["sel"] || 0) || 0;
            var pendiente = parseFloat(data["pendiente"]) || 0.0;
            var penalizacion = parseFloat(data["penalizacion"]) || 0.0;

            /*
            //Esto no debería ocurrir
            if (prop == "cobrado") {
              var total = parseFloat(data["total"]) || 0.0;
              var porcentaje_cobrado = (parseFloat(newVal) / total) * 100;
              if (!isFinite(porcentaje_cobrado)) porcentaje_cobrado = 0.0;
              hf.setDataAtRowProp(
                row,
                "porcentaje_cobrado",
                porcentaje_cobrado,
                "auto"
              );
            }*/
            if (prop == "calculo") {
              hf.setDataAtRowProp(
                row,
                "comision",
                parseFloat(newVal) - (penalizacion / 100) * parseFloat(newVal),
                "auto"
              ); //TODO: Restar penalización
            }
            if (prop == "comision" || prop == "tipo_cobro") {
              var comision = parseFloat(data["comision"]) || 0.0;
              var porcentaje_cobrado =
                parseFloat(data["porcentaje_cobrado"]) || 0.0;
              switch (data["tipo_cobro"]) {
                case "I":
                  hf.setDataAtRowProp(row, "liquidable", comision, "auto");
                  break;
                case "P":
                  hf.setDataAtRowProp(
                    row,
                    "liquidable",
                    (comision * porcentaje_cobrado) / 100,
                    "auto"
                  );
                  break;
                case "T":
                  hf.setDataAtRowProp(
                    row,
                    "liquidable",
                    comision * (porcentaje_cobrado == 100),
                    "auto"
                  );
                  break;
              }
            }
            if (prop == "liquidable") {
              var liquidado = parseFloat(data["liquidado"]) || 0.0; //Liquidado
              hf.setDataAtRowProp(
                row,
                "pendiente",
                parseFloat(newVal) - liquidado,
                "auto"
              );
            }
            if (prop == "pendiente" || prop == "sel") {
              if (sel) {
                hf.setDataAtRowProp(row, "liquidar", pendiente, "auto");
              } else {
                hf.setDataAtRowProp(row, "liquidar", 0.0, "auto");
              }
            }
            if (prop == "liquidar" || prop == "sel") {
              self.calcularComisionTotal();
            }
          });
        }
      },
      htSettingsLineas: {
        beforeChange: function (changes, source) {
          if (!changes) return;
          var h = this;
          window.$.each(changes, function (index, change) {
            var row = change[0];
            var prop = change[1];
            var oldVal = change[2];
            var newVal = change[3];
            // Restringir campo tarifa
            if (prop == "c") {
              if (newVal == "d") changes[index][3] = "D";
              else if (newVal == "i") changes[index][3] = "I";
              else if (window.$.inArray(newVal, ["D", "I"]) == -1)
                changes[index][3] = "D";
            }
            if (prop == 'comision' && source != 'confirm') {
              changes[index] = null;
              self.app.confirm(
                "Está modificando la comisión calculada para esta línea. ¿Está seguro?",
                () => h.setDataAtRowProp(row, 'comision', newVal, 'confirm'));
            }
          });
        },
        afterChange: function (changes, source) {
          if (!changes) return;
          var h = this;
          //var hf = v.find('.field[data-name="facturas"]').find('[name="table"]').handsontable("getInstance");
          var rf = self.facturaSeleccionada;
          //var comisiones = v.find('.field[data-name="lineas"]').data('comisiones') || {};
          window.$.each(changes, function (index, change) {
            var row = change[0];
            var prop = change[1];
            var oldVal = change[2];
            var newVal = change[3];

            if (prop == "c" || prop == "com") {
              //Calcular comisión línea
              //var comision
              self.calcularComisionLinea(rf, row);
            }

            if (prop == "comision") {
              if (source != "auto") {
                h.setDataAtRowProp(row, "comision_manual", 1, "auto");
              } else {
                h.setDataAtRowProp(row, "comision_manual", 0, "auto");
              }
              self.calcularComisionFactura(rf);
            }
          });
        }
      },
      sequence: { name: "liquidacion_0" }
    };
  },
  methods: {
    finalizar() {
      var self = this;
      let initial_data = self.$utils.misc.clone(self.formData);
      delete initial_data["facturas"];
      delete initial_data["incluir"];

      let la = self.lineasSelected().filter(doc => !parseInt(doc.wt));
      let lb = self.lineasSelected().filter(doc => parseInt(doc.wt));
      la.forEach(function (v) {
        delete v.sel;
      });
      lb.forEach(function (v) {
        delete v.sel;
      });
      if (la.length) {
        let initial_data_a = self.$utils.misc.clone(initial_data);
        initial_data_a["lineas"] = la;
        window.console.log(initial_data_a);
        self.app.openWindow("liquidacion_comision", {
          props: {
            initialFormData: initial_data_a,
            initialMode: "new"
          }
        });
      }
      if (lb.length) {
        let initial_data_b = self.$utils.misc.clone(initial_data);
        initial_data_b["lineas"] = lb;
        self.app.openWindow("liquidacion_comision", {
          props: {
            initialFormData: initial_data_b,
            initialMode: "new"
          }
        });
      }
      self.$emit("close");
    },
    onChange(arrProps, callback) {
      this.$on("change", function (prop, source) {
        if (this.mode != "new" && this.mode != "edit") return;
        if (!arrProps.includes(prop)) return;
        callback(source);
      });
    },
    calcularComisionLinea: function (rf, r) {
      var self = this;
      if (!self.formData.facturas[rf]) return 0;
      let l = self.formData.facturas[rf].factura_cliente_id.lineas[r];
      var calculo = 0;
      var comisiones = self.comisiones;
      //window.console.log('calcularComisionLinea.1', rf, r, l.com);
      var lcomid =
        l.com && window.$.isPlainObject(l.com) ? l.com.codigo : l.com;
      if (!parseInt(lcomid)) return 0;

      //window.console.log('calcularComisionLinea.2', lcomid, comisiones, l);
      if (lcomid in comisiones) {
        var comrep = comisiones[lcomid];
        switch (comisiones[lcomid].t) {
          case "U":
            calculo +=
              floatOrZero(l.unidades) *
              ((l.c == "D") * floatOrZero(comrep.unidad_directas) +
                (l.c == "I") * floatOrZero(comrep.unidad_indirectas));
            break;
          case "P":
            calculo +=
              floatOrZero(l.peso) *
              ((l.c == "D") * floatOrZero(comrep.peso_directas) +
                (l.c == "I") * floatOrZero(comrep.peso_indirectas));
            break;
          case "V":
            calculo +=
              floatOrZero(l.totallinea) *
              ((l.c == "D") *
                (parseInt(l.m) * floatOrZero(comrep.venta_directas_metal) +
                  !parseInt(l.m) *
                  floatOrZero(comrep.venta_directas_metal_ni)) +
                (l.c == "I") *
                (parseInt(l.m) * floatOrZero(comrep.venta_indirectas_metal) +
                  !parseInt(l.m) *
                  floatOrZero(comrep.venta_indirectas_metal_ni))) * 0.01;
            break;
        }
        l.calculo = calculo;
        if (!parseInt(l.comision_manual)) l.comision = calculo;
        self.calcularComisionFactura(rf);
        self.$refs.lineas.field.renderH();
      } else {
        window.console.log("No existe la comisión para ese representante");
      }
    },
    calcularComisionFactura: function (rf) {
      var self = this;
      let calculo = self.formData.facturas[rf].factura_cliente_id.lineas.reduce(
        (p, l) => p + floatOrZero(l.comision),
        0
      );
      let f = self.formData.facturas[rf];
      f.calculo = calculo;
      f.comision = calculo;
      f.liquidable =
        f.tipo_cobro == "I"
          ? f.comision
          : f.tipo_cobro == "P"
            ? (f.comision * f.porcentaje_cobrado) / 100
            : f.comision * (f.porcentaje_cobrado == 100);
      f.pendiente = f.liquidable - f.liquidado;
      f.liquidar = f.pendiente;
      self.$refs.facturas.field.renderH();
    },
    calcularComisionTotal: function () {
      var self = this;
      var calculo = self.formData.facturas.reduce(
        (p, f) => p + (parseInt(f.sel) ? floatOrZero(f.liquidar) : 0),
        0
      );
      self.$set(self.formData, "importe_calculado", calculo.toFixed(2));
      self.$emit("change", "importe_calculado", "auto");
    },
    classButtonTabDisabled(i) {
      return { disabled: this.tabDisabled(i) };
    },
    onActiveTab(i) {
      var self = this;
      self.activeTab = i;
      switch (i) {
        case 1:
          self.comisiones = self.formData.representante_id.tipos_comision.reduce(
            (obj, item) => ((obj[item.comision_id.codigo] = item), obj),
            {}
          );
          //Cargamos las facturas del representante a liquidar
          //TODO filtros
          var filter = [
            ["representante_id", "=", self.formData.representante_id.codigo]
          ];
          if (self.formData.delegacion_desde)
            filter.push([
              "delegacion_id",
              ">=",
              self.formData.delegacion_desde
            ]);
          if (self.formData.delegacion_hasta)
            filter.push([
              "delegacion_id",
              "<=",
              self.formData.delegacion_hasta
            ]);
          if (self.formData.factura_desde)
            filter.push(["ndoc", ">=", self.formData.factura_desde]);
          if (self.formData.factura_hasta)
            filter.push(["ndoc", "<=", self.formData.factura_hasta]);
          if (self.formData.ffactura_desde)
            filter.push(["fecha", ">=", self.formData.ffactura_desde]);
          if (self.formData.ffactura_hasta)
            filter.push(["fecha", "<=", self.formData.ffactura_hasta]);
          self.loading = true;
          window.DB.getList("factura_cliente", {
            filter,
            fields: [
              { name: "delegacion_id", fields: ["nombre"] },
              //"serie",
              "ndoc",
              "fecha",
              { name: "cliente_id", fields: ["nombre_comercial"] },
              "total",
              { name: "divisa_id", fields: ["nombre"] },
              "cambio",
              { name: "formapago_id", fields: ["descripcion"] },
              "cobrado",
              "liquidado",
              {
                name: "lineas",
                fields: [
                  "ean13",
                  { name: "articulo_id", fields: ["codigo"] },
                  "descripcion",
                  "m",
                  { name: "com", fields: ["descripcion"] },
                  "c",
                  "unidades",
                  "peso",
                  "precio",
                  "descuentos",
                  "totallinea"
                ]
              }
            ]
          }).then(function (res) {
            //TODO: calcular porcentaje cobrado
            let ll = res.data.map(v => ({
              factura_cliente_id: v,
              tipo_cobro: "I",
              liquidado: floatOrZero(v.liquidado),
              liquidar: 0.0,
              pendiente: 0.0,
              penalizacion:
                floatOrZero(
                  self.formData.representante_id &&
                  self.formData.representante_id.penalizacion_forma_pago
                ) *
                floatOrZero(
                  self.formData.representante_id &&
                  self.formData.representante_id.forma_pago_id &&
                  self.formData.representante_id.forma_pago_id.penalizacion
                ),
              cobrado: 0.0,
              porcentaje_cobrado: 0.0
            }));
            self.$set(self.formData, "facturas", ll);
            self.$nextTick(function () {
              self.formData.facturas.forEach(function (f, rf) {
                (f.factura_cliente_id.lineas || []).forEach(function (l, r) {
                  self.calcularComisionLinea(rf, r);
                });
              });
            });
          }).finally(_ => self.loading = false);
          break;
        case 3:
          //TODO: abrir mantenimiento
          break;
      }
    },
    lineasSelected() {
      var self = this;
      let res = [];
      return self.formData.facturas
        ? self.formData.facturas.filter(doc => parseInt(doc.sel))
        : [];
    },
    tabDisabled(i) {
      var self = this;
      switch (i) {
        case 0:
          return false;
        case 1:
          return (
            !self.formData.delegacion_id ||
            !self.formData.representante_id ||
            !self.formData.divisa_id
          );
        case 2:
          return !self.lineasSelected().length; //!self.docsSelected().length;
        default:
          return true;
      }
    },
    onChangeRepresentante() {
      var self = this;
      if (self.formData.representante_id) {
        self.$set(self.formData, "irpfp", self.formData.representante_id.irpf);
        self.$set(self.formData, "ivap", self.formData.representante_id.impuesto_id?.valor || 0);
        self.$set(
          self.formData,
          "importe_fijo",
          self.formData.representante_id.comision_fija_liquidacion
        );
        self.$set(
          self.formData,
          "nacionalidad",
          self.formData.representante_id.nacionalidad
        );
      } else {
        self.$set(self.formData, "irpfp", null);
        self.$set(self.formData, "ivap", null);
        self.$set(self.formData, "importe_fijo", null);
        self.$set(self.formData, "nacionalidad", null);
      }
    }
  },
  mounted() {
    var self = this;

    self.updateSequence();

    self.$refs["main-tabs"].$refs.toolbar.addEventListener("dblclick", function (
      e
    ) {
      if (self.app.session.mode == "a") return;
      if (e.target == self.$refs["main-tabs"].$refs.toolbar) {
        if (self.mode != "edit" && self.mode != "new" && self.mode != "search")
          return;
        self.showWt = true;
        self.$set(self.formData, "wt", !parseInt(self.formData.wt) ? 1 : 0);
        self.$emit("change", "wt", "edit");
        if (self.mode == "search") self.setFilter(self.getFilter());
      }
    });

    self.onChange(["importe_calculado", "importe_fijo"], function (source) {
      self.$set(
        self.formData,
        "base_imponible",
        (
          floatOrZero(self.formData.importe_calculado) +
          floatOrZero(self.formData.importe_fijo)
        ).toFixed(2)
      );
      self.$emit("change", "base_imponible", "auto");
    });

    self.onChange(["base_imponible", "ivap"], function (source) {
      self.$set(
        self.formData,
        "iva",
        (
          (floatOrZero(self.formData.base_imponible) *
            floatOrZero(self.formData.ivap)) /
          100
        ).toFixed(2)
      );
      self.$emit("change", "iva", "auto");
    });

    self.onChange(["base_imponible", "irpfp"], function (source) {
      self.$set(
        self.formData,
        "irpf",
        (
          (floatOrZero(self.formData.base_imponible) *
            floatOrZero(self.formData.irpfp)) /
          100
        ).toFixed(2)
      );
      self.$emit("change", "irpf", "auto");
    });

    self.onChange(["base_imponible", "iva", "irpf"], function (source) {
      self.$set(
        self.formData,
        "total",
        (
          floatOrZero(self.formData.base_imponible) +
          floatOrZero(self.formData.iva) -
          floatOrZero(self.formData.irpf)
        ).toFixed(2)
      );
      self.$emit("change", "total", "auto");
    });
  }
};
</script>